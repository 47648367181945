// Copyright LEX AI GmbH. All Rights Reserved.
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

function Layout({ title, description, children, author, imageUrl, imageAlt, twitterHandle }) {
  const { locale } = useRouter();

  const url = `https://web.lexai.co/${locale}`;

  // The post title has a maximum of 70 characters
  if (title.length > 70) {
    throw Error('Post title exceeds 70 characters');
  }

  // The post title has a maximum of 200 characters
  if (description.length > 200) {
    throw Error('Post description exceeds 200 characters');
  }

  return (
    <>
      <Head>
        <link rel="icon" href="static/image/icon/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="apple-touch-icon" sizes="180x180" href="/static/image/icon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="/static/image/icon/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/static/image/icon/favicon-16x16.png" sizes="16x16" />
        <link rel="mask-icon" href="/static/image/icon/safari-pinned-tab.svg" color="#ff4400" />

        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <meta name="author" content={author} />

        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta name="twitter:card" content="summary_large_image" />

        {twitterHandle && <meta name="twitter:creator" content={twitterHandle} />}

        <meta property="og:image" content={imageUrl} />
        <meta property="twitter:image" content={imageUrl} />

        <meta property="og:image:alt" content={imageAlt} />
        <meta property="twitter:image:alt" content={imageAlt} />

        {/* Add hreflang links */}
        <link rel="alternate" href="https://web.lexai.co" hrefLang="x-default" />
        {/* {[...locales].sort().map((locale) => (
          <link rel="alternate" href={baseUrl + locale} hrefLang={locale} />
        ))} */}

        <link rel="alternate" href="https://web.lexai.co" hrefLang="en" />
        <link rel="alternate" href="https://web.lexai.co/de" hrefLang="de" />
        <link rel="alternate" href="https://web.lexai.co/fr" hrefLang="fr" />
      </Head>
      <div className="bg-white">
        <div className={'relative'}>{children}</div>
      </div>
    </>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  twitterHandle: PropTypes.string,
};

Layout.defaultProps = {
  title: 'LEX AI Homepage',
  description: 'LEX AI Homepage - English',
  author: 'LEX AI',
  imageUrl: 'https://lexai.co/static/image/previews/1200x628/default.png',
  imageAlt: 'Preview Image',
  children: [],
};

export default Layout;
