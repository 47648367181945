import React from 'react';
import Layout from '@components/Layout';
import { getCurrentSession } from '@lib/amplify-auth';
import { Routes } from '@lib/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function Home({ dir }) {
  const router = useRouter();
  const intl = useIntl();

  const title = intl.formatMessage({ id: 'page.home.head.title' });
  const description = intl.formatMessage({
    id: 'page.home.head.meta.description',
  });

  useEffect(() => {
    getCurrentSession().then(
      (session) => {
        router.replace(`/${router.locale}${Routes.home}`, `/${router.locale}${Routes.home}`, {
          shallow: true,
        });
      },
      (reason) => {
        router.replace(`/${router.locale}${Routes.login}`, `/${router.locale}${Routes.login}`, {
          shallow: true,
        });
      }
    );
  }, [router]);

  return (
    <Layout
      title="LEX AI web app"
      description="Regulatory news and updates by LEX AI"
      author="LEX AI"
      twitterHandle="@LEXAI12"
    >
      <div className="h-screen w-screen flex flex-row justify-center items-center">
        <Image className="" src="/static/image/logo/logo-no-bg.png" alt="LEX AI splash logo" height={512} width={512} />
      </div>
    </Layout>
  );
}
